// Here you can add other styles
.red-star {
  color: red;
}

.no-select {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                    supported by Chrome, Edge, Opera and Firefox */
}

.navbar-brand-full {
  object-fit: contain;
}

.form-control {
  appearance: none;
}

select.form-control {
  cursor: pointer;
  background: url(../assets/img/icons/selectArrow.svg) no-repeat center right 10px / 10px 7px;
}
